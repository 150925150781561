/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Variables */
@import '_variables.scss';

/* Ocultar las flechas para Chrome, Edge, y Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Ocultar las flechas para Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body{
    font-family: "Nunito",sans-serif !important;
    font-weight: 500;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
h1,h2,h3,h4,h5, th{
    font-family: "Montserrat",sans-serif;
}

h4{
    font-size: 20px !important;
}

h5{
    font-size: 18px !important;
}

h6{
    font-size: 16px !important;
}

span,label, strong{
    color: $text-primary;
}
.form-select {
    --bs-form-select-bg-img: url("./assets/icons/arrow-down.svg") !important
  }
::ng-deep .form-select {

--bs-form-select-bg-img: url("./assets/icons/arrow-down.svg") !important
}
.btn-close {
    --bs-btn-close-bg: url("./assets/icons/cruz-modal.svg") !important;
}
.form-check-input {
    border-color: $primary !important;
    border-radius: 3px;
}
::ng-deep {
    .p-checkbox-box {
        border-color: $primary !important;
        border-radius: 3px;
    }
    .p-radiobutton-box {
        border-color: $primary !important;
    }

    .p-stepper-panels {
        width: 100% !important;
    }
}

.otic.title{
    color: $primary;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
}

.otic.link{
    color: $primary-highlight;
}

.border-left{
    &.info,&.waiting{
        border-left: 6px solid rgba($color: $color-info, $alpha: 0.2);
    }
    &.warning{
        border-left: 6px solid rgba($color: $color-warning, $alpha: 0.2);
    }
    &.success{
        border-left: 6px solid rgba($color: $color-success, $alpha: 0.2);
    }
    &.error{
        border-left: 6px solid rgba($color: $color-error, $alpha: 0.2);
    }
}

.btn{
    min-height: $btn-min-height;
    min-width: $btn-min-width;
    &.btn-primary{    
        &.active{
            background-color: #ffff !important;
            color: $primary !important;
        }
    }

    i{
        font-size: 1rem;
        padding: 0px;
    }
}

.breadcrumb-item{
    &:first-child > a{
        color: #474756;
    }
    a{
        text-decoration: none!important;
    }
}

.p-datatable-table{
    td{
        font-size: 14px;
    }
    .p-datatable-tbody{
        td.opciones{
            max-width: 300px;
        }
    }
    

    .p-datatable-tfoot{
        .btn{
            min-width: 24px;
            min-height: 20px;
            border-radius: 6px;
            padding: 2px 4px 2px 4px;
            width: 24px;
            height: 20px;
            display: grid;
            place-content: center;
            i{
                font-size: 8px !important;
                &::before{
                    font-weight: bold !important;
                }
            }
        }
    }
}

.form-control:disabled {
    background-color: #F5F3F7 !important;
    border: 1px solid #D4D2D5 !important;
    color: #6c757d;
    opacity: 1;
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width: 90%!important;
        /* width: 90%; */
    }
  }
.disabled {
    background-color: #F5F3F7 !important;
    border: 1px solid #D4D2D5 !important;
    color: #6c757d;
    opacity: 1;
    pointer-events: none;
}