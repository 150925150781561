
/* Theme */
$primary: #184D9B;
$primary-highlight: #266FE0;
$text-primary: #1D2345;
$color-info: #184D9B;
$color-success: #00C56B;
$color-warning: #FFD058;
$color-error: #ED475B;
$color-cruz: #042E6C;


/* Size */
$nav-bar: 80px;
$nav-bar-menu: 72px;
$footer: 306px;
$btn-min-width: 9.25rem;
$btn-min-height: 2.5rem;

/* Bootstrap Override */
h1,h2,h3,h4,h5{
    --bs-heading-color: #1D2345 !important;
    display:flex;
    align-items: center;
}

.btn{

    &.btn-primary{
        --bs-btn-color: #ffff !important;
        --bs-btn-bg: #184D9B !important;
        &:hover{
            --bs-btn-hover-bg: #ffff !important;
            --bs-btn-hover-color: #184D9B !important;
        }
    }
    
    &.btn-secondary{
        --bs-btn-color: #184D9B !important;
        --bs-btn-bg: #ffff !important;
        &:hover{
            --bs-btn-hover-bg: #184D9B !important;
            --bs-btn-hover-color: #ffff !important;
        }
    }
}


.list-group-item{
    --bs-list-group-bg: transparent;
}

.breadcrumb-item{
    &::before{
        --bs-breadcrumb-divider: "-"
    }
}

.form-label, .form-check-label, p{
    display: flex;
    align-items: center;
}

/* PrimeNG Override */
.p-component{
    --font-family: "Nunito",sans-serif;
}

.p-element.is-invalid.ng-dirty {
    >.p-dropdown{
        border-color: #f44336;
    }
    >.p-multiselect{
        border-color: #f44336;
    }
}
